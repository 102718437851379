import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
declare var $: any;


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  userId: any;

  city: any;
  contact_us_url: any;
  country: any;
  cus_permission: any;
  customerAddress1: any;
  customerAddress2: any;
  customerId: any;
  customerName: any;
  customerPhone: any;
  email: any;
  state: any;
  zipCode: any;
  logo: any;
  userperms: any;
  accesstoken: any;
  showfields = false;
  isReadOnly = true;
  // USED FOR FILE UPLOADING
  file: any;
  img: any;
  newfile: File;
  constructor(private apiservice: ServicesService, public router: Router) {
    this.accesstoken = localStorage.getItem('access_token');
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('customerId');
    this.listProfileDetails();

    // var self = this;
    // var _URL = window.URL || window.webkitURL;

    // $("#imgUploadField").change(function (e) {
    //   // var file, img;
    //   alert("hello");
    //   self.readURL(this);

    //   if ((self.file = $('#imgUploadField')[0].files[0])) {
    //     self.img = new Image();
    //     self.img.onload = function () {
    //       if (this.width == '575') { } else { alert('Please update the image with size 575*275'); $("#imgUploadField").val(''); return false; }
    //       if (this.height == '275') { } else { alert('Please update the image with size 575*275'); $("#imgUploadField").val(''); return false; }
    //     };
    //     self.img.onerror = function () {
    //       alert("not a valid file: " + self.file.type);
    //       return false;
    //     };
    //     self.img.src = _URL.createObjectURL(self.file);

    //   }

    // });
  }


  readURL(input): void {
    console.log(input);

      if (input.target.files && input.target.files[0]) {
          const file = input.target.files[0];
  
          const reader = new FileReader();
          reader.onload = e => $('#logo-img').attr('src', reader.result);
  
          reader.readAsDataURL(file);
      }


    // if (input.files && input.files[0]) {
    //   var reader = new FileReader();
    //   var self = this;
    //   reader.onload = function (e) {
    //     console.log(e);
    //     $('.logo-img div img').attr('src', e.target.result);
    //     self.logo =e.target.result;
    //   }

    //   reader.readAsDataURL(input.files[0]); // convert to base64 string
    // }

    // if (input.files && input.files[0]) {
    //   const file = input.files[0];

    //   // const reader = new FileReader();
    //   // reader.onload = e => {
    //   //   console.log('hello');
    //   //   this.logo = reader.result;
    //   // }

    //   // reader.readAsDataURL(file);
    //   const reader = new FileReader();
    //   // this.imagePath = file;
    //   reader.readAsDataURL(file);
    //   reader.onload = (_event) => {
    //     console.log('hello');
    //     this.logo = reader.result;
    //   }
    // }


  }

  changeListener(event: any) {
    this.newfile = event.target.files[0];
    // this.newfile = event;
    // console.log("testing files");
    console.log(this.newfile);

    var self = this;
    var _URL = window.URL || window.webkitURL;

    // $("#imgUploadField").change(function (e) {
    var file, img;

    self.readURL(event);

    if ((self.file = $('#imgUploadField')[0].files[0])) {
      self.img = new Image();
      self.img.onload = function () {
        if (this.width == '575') { } else { alert('Please update the image with size 575*275'); $("#imgUploadField").val(''); return false; }
        if (this.height == '275') { } else { alert('Please update the image with size 575*275'); $("#imgUploadField").val(''); return false; }
      };
      self.img.onerror = function () {
        alert("not a valid file: " + self.file.type);
        return false;
      };
      self.img.src = _URL.createObjectURL(self.file);

    }

    // });


  }

  listProfileDetails() {

    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'profile_details';
    ProfiledetailsReq.nx32_customer_id = this.userId;
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.status == 'true') {
        // customer_details: {
        //   city: "Coimbatore"
        //   contact_us_url: null
        //   country: "India"
        //   cus_permission: ["101", "102", "103", "105", "104", "109", "134", "110", "118", "156", "157", "151", "152", "153",]
        //   customerAddress1: "Coimbatore"
        //   customerAddress2: "CBE"
        //   customerId: "5228"
        //   customerName: "navin"
        //   customerPhone: "8220733686"
        //   email: "nb@cal4care.com,test@cal4care.com"
        //   logo: "https://erp.cal4care.com/nx32/images/logo-white.svg"
        //   state: "Tamil Nadu"
        //   zipCode: ""
        // }
        this.city = response.customer_details.city;
        this.contact_us_url = response.customer_details.contact_us_url;
        this.country = response.customer_details.country;
        this.cus_permission = localStorage.setItem('userperms', response.customer_details.cus_permission);
        this.customerAddress1 = response.customer_details.customerAddress1;
        this.customerAddress2 = response.customer_details.customerAddress2;
        this.customerId = response.customer_details.customerId;
        this.customerName = response.customer_details.customerName;
        this.customerPhone = response.customer_details.customerPhone;
        this.logo = response.customer_details.logo;
        this.email = response.customer_details.email;
        this.state = response.customer_details.state;
        this.zipCode = response.customer_details.zipCode;

        this.userperms = localStorage.getItem('userperms').split(',');

        for (var i = 0; i < this.userperms.length; i++) {
          if (this.userperms[i] == '155')
            this.showfields = true;
        }

      } else if (response.status == 'false') {

      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }


  editAgentData() {

    var filename = $("#imgUploadField").val();
    // var testing = $('#imgUploadField')[0].files[0];
    var testing = this.newfile;
    // console.log(this.newfile);
    // console.log(this.newfile.type);
    if (testing != null && testing != undefined) {
      const file = this.newfile.type;
      const validImageTypes = ["image/png"];
      if (!validImageTypes.includes(file)) {
        alert('Please upload png file');
        return false;
      }
    }
    // var extension = filename.replace(/^.*\./, '');
    // if ((this.file = $('#imgUploadField')[0].files[0])) {
    //   if (extension != 'png') {
    //     alert('Please upload png file');
    //     return false;
    //   }
    // }
    var formData = new FormData();
    // var agent_req = {
    //   cust_id: $("#cust_id").val(),
    //   contact_us_url: $("#contact_us_url").val()
    // }
    var agent_req = {
      customer_id: this.userId,
      action: 'update_profile',
      moduleType: 'profile',
      contact_us_url: this.contact_us_url,

    }

    var json_arr = JSON.stringify(agent_req);
    formData.append('logo', this.newfile);
    // formData.append('element_data', json_arr);
    formData.append('operation', 'curlData');
    formData.append('moduleType', 'profile');
    formData.append('access_token', this.accesstoken);
    formData.append('api_type', 'web');
    formData.append('customer_id', this.userId);
    formData.append('contact_us_url', this.contact_us_url);
    formData.append('action', 'update_profile');
    $.ajax({
      url: "https://erp.cal4care.com/nx32/ind.php",
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {

        // console.log(data);
        // location.reload();
      }
    });

  }

}
