import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { NgApexchartsModule } from "ng-apexcharts";
import { GraphQLModule } from './graphql.module';
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


import { NewComponentComponent } from './new-component/new-component.component';
import { ServicesService } from "./services.service";
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { LicenseListComponent } from './license-list/license-list.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ThemeSettingsComponent } from './theme-settings/theme-settings.component';
import { AddResellerComponent } from './add-reseller/add-reseller.component';
import { AssignDevicesComponent } from './assign-devices/assign-devices.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResellerListComponent } from './reseller-list/reseller-list.component';


@NgModule({
  declarations: [
    AppComponent,
    NewComponentComponent,
    LoginComponent,
    DashboardComponent,
    LicenseListComponent,
    UserProfileComponent,
    UserEditComponent,
    ForgotPasswordComponent,
    ThemeSettingsComponent,
    AddResellerComponent,
    AssignDevicesComponent,
    ResellerListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgApexchartsModule,
    GraphQLModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
