import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
declare var iziToast: any;
declare var $: any;
@Component({
  selector: 'app-assign-devices',
  templateUrl: './assign-devices.component.html',
  styleUrls: ['./assign-devices.component.css']
})
export class AssignDevicesComponent implements OnInit {
  accesstoken: any;
  userId: any;
  List: any;
  login_types: any;
  timers: any;
  dist_ids: any;
  endusers: any;
  customer_details: any;
  selectedReseller: any;
  selectedlicense: any;
  binding: any;
  user_identity: any;
  showdist = false;
  showreseller = false;
  showend = false;
  showfalsestate = false;
  showerror = false;

  constructor(private apiservice: ServicesService, public router: Router) {
    this.accesstoken = localStorage.getItem('access_token');
    this.userId = localStorage.getItem('customerId');
    this.login_types = localStorage.getItem('login_types');
    this.endusers = localStorage.getItem('end_user');
    this.dist_ids = localStorage.getItem('distributor_id');
    this.user_identity = localStorage.getItem('user_identity');
  }

  ngOnInit(): void {
    this.callListing();
    this.getresellers();
    


    if (this.userId == '5505' && this.login_types == 'D') {
      this.showdist = true;
      this.showreseller = true;
      this.showend = true;
    } else if (this.login_types == 'D') {
      this.showdist = false;
      this.showreseller = true;
      this.showend = true;
    } else if (this.login_types == 'R') {
      if (this.endusers == 0) {
        this.showdist = false;
        this.showreseller = false;
        this.showend = true;
      } else if (this.endusers == 1) {
        this.showdist = false;
        this.showreseller = false;
        this.showend = false;
      }
    }


  }

  toast(messages, colors) {
    iziToast.show({
      message: messages,
      position: 'topRight',
      timeout: 3000,
      color: colors,
      theme: 'light'

    });
  }

  callListing() {

    if (this.login_types == 'D') {
      this.licenseList();

    } else if (this.login_types == 'R') {
      this.resellerlicenselist();
    }
  }

  searchvalues(inp) {
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"license",
    // "element_data":{"action":"license","customer_id":"349","limit":"50","offset":"0","search":"N3X2L20201012"}}

    console.log(inp.target.value);
    var searchvalues = inp.target.value;
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'license';
    ProfiledetailsReq.customer_id = this.userId;
    ProfiledetailsReq.limit = '50';
    ProfiledetailsReq.offset = '0';
    ProfiledetailsReq.search = searchvalues;
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.status == 'true') {
        this.List = response.license_list;
        console.log(this.List);
        this.showfalsestate = false;
        // this.userperms = localStorage.getItem('userperms').split(',');

        // for (var i = 0; i < this.userperms.length; i++) {
        //   if (this.userperms[i] == '155')
        //   this.showfields = true;
        // }

      } else if (response.status == 'false') {
        this.showfalsestate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }

  licenseList() {
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'license_list';
    ProfiledetailsReq.customer_id = this.userId;
    ProfiledetailsReq.limit = '25';
    ProfiledetailsReq.offset = '0';
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.status == 'true') {
        this.List = response.license_list;
        console.log(this.List);
        // this.userperms = localStorage.getItem('userperms').split(',');

        // for (var i = 0; i < this.userperms.length; i++) {
        //   if (this.userperms[i] == '155')
        //   this.showfields = true;
        // }
        this.showfalsestate = false;
      } else if (response.status == 'false') {
        this.showfalsestate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  }


  resellerlicenselist() {
    // {"access_token":"","api_type":"web","operation":"curlData",
    // "moduleType":"reseller","element_data":{"action":"reseller_devices",
    // "dist_id":"349","reseller_id":"1"}} 

    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'reseller_devices';
    ProfiledetailsReq.dist_id = this.dist_ids;
    ProfiledetailsReq.reseller_id = this.userId;
    // ProfiledetailsReq.limit = '25';
    // ProfiledetailsReq.offset = '0';
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.result_code == '111') {
        if (this.login_types == 'D') {
          this.List = response.license_list;
        } else if (this.login_types == 'R') {
          this.List = response.license_list.ticket_options;
        }
        
        console.log(this.List);
        this.showfalsestate = false;
      } else if (response.result_code == '222') {
        this.showfalsestate = true;
      }
    }, (error) => {

      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  getresellers() {
    // {"access_token":"","api_type":"web","operation":"get_reseller",
    // "moduleType":"reseller","element_data":{"action":"get_reseller",
    // "dist_id":"349","end_user":"0"}}
    const getResellerDetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'get_reseller',
      'moduleType': 'reseller',
      'element_data': getResellerDetailsReq,
    });

    getResellerDetailsReq.action = 'get_reseller';
    if(this.user_identity == 'enduser' || this.user_identity == 'reseller'){
      getResellerDetailsReq.dist_id = this.dist_ids;
      getResellerDetailsReq.reseller_id = this.userId;
    }else{
      getResellerDetailsReq.dist_id = this.userId;
    }
    // getResellerDetailsReq.dist_id = this.userId;
    if (this.login_types == 'D') {
      getResellerDetailsReq.end_user = '0';
    } else if (this.login_types == 'R') {
      getResellerDetailsReq.end_user = '1';
    }
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.result_code == '111') {
        this.customer_details = response.data;
        this.showfalsestate = false;
      } else if (response.status == 'false') {
        this.showfalsestate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });


  }

  updateCheckedOptions(ids, events) {
    if(events.target.checked == true){
    this.selectedlicense = events.target.value;
    }else{
      this.selectedlicense = null;
    }
    console.log(events);
  }

  getdistributorvalues(events) {
    // alert('hello');
    this.selectedReseller = events.target.value;
    console.log(this.selectedReseller);
  }
  // testing() {
  //   Swal.fire({
  //     title: 'Please Wait',
  //     html: '<b></b> minutes, WAN Static IP updated and device will get reboot',
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timer: 180000,
  //     onOpen: () => {
  //       var minute = 2;
  //       var sec = 59;
  //     var intervalId = setInterval(function () {
  //         // document.getElementById("timer").innerHTML = minute + " : " + sec;
  //         console.log(minute + " : " + sec);
  //         var timers = minute + " : " + sec;
  //         const content = Swal.getContent();
  //         if (content) {
  //           const b = content.querySelector('b');
  //           if (b) {
  //             b.textContent = timers
  //           }
  //           sec--;
  //           if (sec == 0o0) {
  //             minute--;
  //             sec = 60;
  //             if (minute == -1) {
  //               // minute = 0;
  //               clearInterval(intervalId);
  //             }
  //           }
  //         }
  //       }, 1000);
  //       Swal.showLoading();
  //     }
  //   }).then(
  //     () => { },
  //     (dismiss) => {
  //       if (dismiss === 'timer') {
  //         console.log('closed by timer!!!!');
  //         Swal.fire({
  //           title: 'Devices Rebooted!',
  //           // type: 'success',
  //           icon: 'success',
  //           timer: 2000,
  //           showConfirmButton: false
  //         })
  //       }
  //     }
  //   )


  // }


  // Settimer() {
  //   console.log('testing');
  //   var minute = 2;
  //   var sec = 59;
  //   setInterval(function () {
  //     // document.getElementById("timer").innerHTML = minute + " : " + sec;
  //     console.log(minute + " : " + sec);
  //     // this.timers = minute + " : " + sec;
  //     this.timers = '<div id="testing">Hello nainv</div>';

  //     sec--;
  //     if (sec == 0o0) {
  //       minute--;
  //       sec = 60;
  //       if (minute == 0) {
  //         minute = 5;
  //       }
  //     }
  //   }, 1000);
  // }

  assignPopup() {
    if (this.selectedlicense != null) {
      $('#addContactModal').modal('show');
    }else{
      var msg = "Please select atleast One Devices";
      this.toast(msg, 'red');
    }
  }

  disablemodal(){
    $('#addContactModal').modal('hide');
  }

  assigneDevices() {
    // {"access_token":"","api_type":"web","operation":"assign_device","moduleType":"reseller",
    // "element_data":{"action":"assign_device","dist_id":"349","reseller_id":"1","license_id":"1823","status":"1"}}
    let check = $("#select_distributor").val();
    console.log(check);
    if (check != null) {
      this.showerror = false;

      $('#addContactModal').modal('hide');
      const AssignDevicesReq: any = new Object();
      const profileList: any = ({
        'access_token': this.accesstoken,
        'api_type': 'web',
        'operation': 'assign_device',
        'moduleType': 'reseller',
        'element_data': AssignDevicesReq,
      });



      AssignDevicesReq.action = 'assign_device';
      // AssignDevicesReq.dist_id = this.userId;
      if(this.user_identity == 'enduser' || this.user_identity == 'reseller'){
        AssignDevicesReq.dist_id = this.dist_ids;
        AssignDevicesReq.reseller_id = this.userId;
      }else{
        AssignDevicesReq.dist_id = this.userId;
      }
      AssignDevicesReq.reseller_id = this.selectedReseller;
      AssignDevicesReq.license_id = this.selectedlicense;
      AssignDevicesReq.status = '1';
      this.apiservice.newsendServer(profileList).then((response: any) => {
        if (response.result_code == '111') {
          this.callListing();
          Swal.fire({
            title: 'Devices Assigned!',
            text: 'Successfully',
            // type: 'success',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          });
        } else {
          Swal.fire({
            title: 'Failed!',
            text: 'Devices Assigned',
            // type: 'success',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
          });
        }
      }, (error) => {
        console.log(error);
        // this.apiservice.dismiss();
      });

    } else {
      this.showerror = true;
    }
  }

}
