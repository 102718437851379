import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
declare var $: any;
declare var iziToast: any;
@Component({
  selector: 'app-theme-settings',
  templateUrl: './theme-settings.component.html',
  styleUrls: ['./theme-settings.component.css']
})
export class ThemeSettingsComponent implements OnInit {
  userId: any;
  accesstoken: any;
  themes: any;
  layout: any;
  layoutM: any;
  themeM: any;
  login_types: any;
  endusers: any;
  constructor(private apiservice: ServicesService) { }

  ngOnInit(): void {
    this.accesstoken = localStorage.getItem('access_token');
    this.userId = localStorage.getItem('customerId');
    this.layoutM = localStorage.getItem('layout');
    this.themeM = localStorage.getItem('theme_color');
    this.login_types = localStorage.getItem('login_types');
    this.endusers = localStorage.getItem('end_user');
  }

  mytheme(theme){
    this.themes = theme;
    console.log(theme);
  }

 
  myLayout(){
    this.layout = $('[name="choice"]:checked').val();
    console.log(this.layout);
  }

  savetheme() {
    console.log(this.layout);
    console.log(this.themes);

    const themeReq: any = new Object();

    const savethemelist: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': themeReq,
    });
    themeReq.action = 'app_settings';

    themeReq.nx32_customer_id = this.userId;
    if(this.login_types == 'D'){
      themeReq.type = '2';
    }else if(this.login_types == 'R'){
      if(this.endusers == 0){
      themeReq.type = '0';
      }else if(this.endusers == 1){
        themeReq.type = '1';
      }
    }

    if(this.layout !== undefined){
    themeReq.layout = this.layout;
    }else{
      themeReq.layout = this.layoutM;
    }
    if(this.themes !== undefined){
    themeReq.theme_color = this.themes;
    }else{
      themeReq.theme_color = this.themeM;
    }
    this.apiservice.newsendServer(savethemelist).then((response: any) => {
      console.log(response);
      if(response.status == 'true'){
        if(this.themes !== undefined){
        localStorage.setItem('theme_color', this.themes);
        }
        if(this.layout !== undefined){
        localStorage.setItem('layout', this.layout);
        }
        iziToast.success({
          message: "Theme Updated Successfully.",
          position: 'topRight'
      });
       }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }



}
