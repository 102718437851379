import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
// import * as Testing from "../../assets/js/libs/contact";

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.css']
})
export class LicenseListComponent implements OnInit {
  accesstoken: any;
  userId: any;
  dist_ids: any;
  List: any;
  login_types: any;
  mac_address1: any;
  mac_address2: any;
  serial_number: any;
  showfalsestate = false;
  lic_id_array: any = [];

  constructor(private apiservice: ServicesService, public router: Router) {
    this.accesstoken = localStorage.getItem('access_token');
    this.userId = localStorage.getItem('customerId');
    this.login_types = localStorage.getItem('login_types');
    this.dist_ids = localStorage.getItem('distributor_id');
   }

  ngOnInit(): void {
    this.loginDisplayDevices();
  }

  loginDisplayDevices() {
    if (this.login_types == 'D') {
      this.LicenseList();

    } else if (this.login_types == 'R') {
      this.resellerlicenselist();
    }
  }

  resellerlicenselist() {
    // Swal.fire({
    //   // title: 'Updated',
    //   //   position: 'top-end',
    //   title: 'Please Wait....',
    //   imageUrl: "assets/custom-images/pleasewait.gif",
    //   imageHeight: 80, 
    //   imageWidth: 80, 
    //   showConfirmButton: false,
    //   allowOutsideClick: false
    // });

    // {"access_token":"","api_type":"web","operation":"curlData",
    // "moduleType":"reseller","element_data":{"action":"reseller_devices",
    // "dist_id":"349","reseller_id":"1"}} 
    this.lic_id_array = [];
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'reseller_devices';
    ProfiledetailsReq.reseller_id = this.userId;
    ProfiledetailsReq.dist_id = this.dist_ids;
    // ProfiledetailsReq.search_text = this.searchtextval;

    // ProfiledetailsReq.limit = '25';
    // ProfiledetailsReq.offset = '0';
    this.apiservice.newsendServer(profileList).then((response: any) => {
      // Swal.close();
      if (response.result_code == '111') {
        this.List = response.license_list;
        console.log(this.List);
        console.log(this.List.length);
        this.List.forEach(values => {
          var mac1 = values.mac_address1;
          var mac2 = values.mac_address2;
          var sno = values.serial_number;
          var dev_model = values.device_model;
          values.cpu = "";
          values.memory = "";
          values.temp = [];
          var wanip = mac1.toLowerCase();
          var id = btoa('WAN=' + wanip);
          var testid = btoa(id);
          this.lic_id_array.push(testid);
          // this.getdevicestatus();
          // this.sendOnload(mac1);

          // this.getnewdevices(sno, mac1, mac2, dev_model);
        });
        console.log(this.lic_id_array);
        // Swal.close();
      } else if (response.result_code == '222') {
        // Swal.close();
        let messages = 'Failed';
        let texts = 'No devices Found';
        // this.errortoastForm(messages, texts);
      }
    }, (error) => {

      console.log(error);
      // this.apiservice.dismiss();
    });
  }


  LicenseList() {
  
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'license_list';
    ProfiledetailsReq.customer_id = this.userId;
    ProfiledetailsReq.limit = '50';
    ProfiledetailsReq.offset = '0';
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.status == 'true') {
        this.List = response.license_list;
             
        // this.userperms = localStorage.getItem('userperms').split(',');

        // for (var i = 0; i < this.userperms.length; i++) {
        //   if (this.userperms[i] == '155')
        //   this.showfields = true;
        // }

      } else if (response.status == 'false') {
        this.showfalsestate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  }

  
  activateLicense(act_type){
    const activateLicenseReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': activateLicenseReq,
    });

    activateLicenseReq.action = 'activate_license';
    activateLicenseReq.customer_id = this.userId;
    activateLicenseReq.serial_number = this.serial_number;
    activateLicenseReq.mac_address1 = this.mac_address1;
    activateLicenseReq.mac_address2 = this.mac_address2;
    activateLicenseReq.activation_type = act_type;
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.status == 'true') {
        
      } else if (response.status == 'false') {
        // this.showfalsestate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }


  searchvalues(inp){
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"license",
    // "element_data":{"action":"license","customer_id":"349","limit":"50","offset":"0","search":"N3X2L20201012"}}
  
    console.log(inp.target.value);
    var searchvalues = inp.target.value;
    const ProfiledetailsReq: any = new Object();
    const profileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'license',
      'element_data': ProfiledetailsReq,
    });

    ProfiledetailsReq.action = 'license';
    ProfiledetailsReq.customer_id = this.userId;
    ProfiledetailsReq.limit = '50';
    ProfiledetailsReq.offset = '0';
    ProfiledetailsReq.search = searchvalues;
    this.apiservice.newsendServer(profileList).then((response: any) => {
      if (response.status == 'true') {
        this.List = response.license_list;
        console.log(this.List);
        this.showfalsestate = false;
        // this.userperms = localStorage.getItem('userperms').split(',');

        // for (var i = 0; i < this.userperms.length; i++) {
        //   if (this.userperms[i] == '155')
        //   this.showfields = true;
        // }

      } else if (response.status == 'false') {
        this.showfalsestate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  
  }


}
