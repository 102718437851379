import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewComponentComponent } from '../app/new-component/new-component.component';
import { LoginComponent } from '../app/login/login.component';
import { DashboardComponent } from '../app/dashboard/dashboard.component';
import { LicenseListComponent } from '../app/license-list/license-list.component';
import { UserProfileComponent } from '../app/user-profile/user-profile.component';
import { UserEditComponent } from '../app/user-edit/user-edit.component';
import { ForgotPasswordComponent } from '../app/forgot-password/forgot-password.component';
import { ThemeSettingsComponent } from '../app/theme-settings/theme-settings.component';
import { AddResellerComponent } from './add-reseller/add-reseller.component';
import { AssignDevicesComponent } from './assign-devices/assign-devices.component';
import { ResellerListComponent } from './reseller-list/reseller-list.component';

const routes: Routes = [
  {
    path: '', component: DashboardComponent
  },
  {
    path: 'newcomponent', component: NewComponentComponent
  },
  {
    path: 'login', component: LoginComponent
  }, 
  {
    path: 'license-list', component: LicenseListComponent
  },
  {
    path: 'profile', component: UserProfileComponent
  },
  {
    path: 'user-edit', component: UserEditComponent
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  {
    path: 'theme-settings', component: ThemeSettingsComponent
  },
  {
    path: 'add-users', component: AddResellerComponent
  },
  {
    path: 'assign-devices', component: AssignDevicesComponent
  },
  {
    path: 'reseller-list', component: ResellerListComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
