import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
// import { IonInput } from '@ionic/angular';
import Swal from 'sweetalert2';
declare var $: any;

function myFunction() {
  
  var x = document.getElementById("passwords");
  // alert(x);
  // if (x.type === "password") {
  //   x.type = "text";
  // } else {
  //   x.type = "password";
  // }
}
@Component({
  selector: 'app-add-reseller',
  templateUrl: './add-reseller.component.html',
  styleUrls: ['./add-reseller.component.css']
})
export class AddResellerComponent implements OnInit {
  accesstoken: any;
  userId: any;
  company_name: any;
  short_name: any;
  email_id: any;
  passwords: any;
  contact_person: any;
  phone_num: any;
  login_type: any;
  listing_users: any;
  part_reseller_id: any;
  searchValue: any;
  nodatastate = false;
  addusers = false;
  editusers = false;
  supered = false;
  othered = false;
  endusers: any;
  disti_id:any;
  user_type:any;
  user_identity:any;
  distributor_id:any;
  reseller_id : any;
  comp_error;
  email_error;
  person_error;
  psd_error;
  num_error;
  short_error;
  constructor(private apiservice: ServicesService, public router: Router) { }

  ngOnInit(): void {
    this.accesstoken = localStorage.getItem('access_token');
    this.userId = localStorage.getItem('customerId');
    // this.disti_id = localStorage.getItem('distributor_id');
    this.login_type = localStorage.getItem('login_types');
    this.endusers = localStorage.getItem('end_user');
    this.user_identity = localStorage.getItem('user_identity');
    this.distributor_id = localStorage.getItem('distributor_id');
    this.user_type = localStorage.getItem('login_email_id');

    this.listingusers();
    if(this.user_type == 'superadmin'){
      this.supered = true;
      this.othered = false;
    }else{
      this.supered = false;
      this.othered = true;
    }
    // myFunction();
  }



  passwordfunc(){
    $("body").on('click', '.toggle-password', function() {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $("#passwords");
      if (input.attr("type") === "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    
    });
    // var newone = $("#passwords").val();
    // var  hello= $("#passwords").type();
    // alert(hello);
  //   function myFunction(){
  //     var x = document.getElementById("passwords");
      
  // }
    }
  
  addbutton() {
    this.addusers = true;
    this.editusers = false;
    $("#company_name").val('');
    $("#email_id").val('');
    $("#passwords").val('');
    $("#contact_person").val('');
    $("#phone_num").val('');
    $("#short_name").val('');
    // this.company_name;
    // this.email_id;
    // this.passwords;
    // this.contact_person;
    // this.phone_num;
    // this.short_name;
  }

  searchField(searchs) {
    this.searchValue = searchs;
    this.listingusers();
  }

  addingusers() {
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller",
    // "element_data":{"action":"add_reseller","dist_id":"349","resellers":"Naveen pte ltd",
    // "email":"nb@cal4care.com","password":"password","contact_person":"ps",
    // "phone":"987654321","end_user":"0","reseller_shortname":"nb"}}


    var newone = $("#company_name").val();
    var newone1 = $("#email_id").val();
    var newone2 = $("#contact_person").val();
    var newone3 = $("#passwords").val();
    var newone4 = $("#phone_num").val();
    var newone5 = $("#short_name").val();
    if (newone.length == 0) {
      this.comp_error = true;
    } else {
      this.comp_error = false;
    }

    if (newone1.length == 0) {
      this.email_error = true;
    } else {
      this.email_error = false;
    }

    if (newone2.length == 0) {
      this.person_error = true;
    } else {
      this.person_error = false;
    }

    if (newone3.length == 0) {
      this.psd_error = true;
    } else {
      this.psd_error = false;
    }

    if (newone4.length == 0) {
      this.num_error = true;
    } else {
      this.num_error = false;
    }


    if (newone5.length == 0) {
      this.short_error = true;
    } else {
      this.short_error = false;
    }


    if (this.company_name != undefined && this.email_id != undefined && this.passwords != undefined && this.contact_person != undefined && this.phone_num != undefined && this.short_name != undefined) {
      // {"access_token":"","api_type":"web","operation":"get_reseller","moduleType":"reseller","element_data":{"action":"get_validation_reseller","dist_id":"5228","search_text":"ps@cal4care.com","end_user":"0"}}

      const ValidationReq: any = new Object();
      const ValidationList: any = ({
        'access_token': this.accesstoken,
        'api_type': 'web',
        'operation': 'get_reseller',
        'moduleType': 'reseller',
        'element_data': ValidationReq,
      });

      ValidationReq.action = 'get_validation_reseller';
      ValidationReq.dist_id = this.userId;
      ValidationReq.search_text = newone1;
      if (this.login_type == 'D') {
        ValidationReq.end_user = '0';
      } else if (this.login_type == 'R') {
        ValidationReq.end_user = '1';
      }
      this.apiservice.newsendServer(ValidationList).then((response: any) => {
        if (response.result_code == '222') {
          const addUserReq: any = new Object();
          const addprofileList: any = ({
            'access_token': this.accesstoken,
            'api_type': 'web',
            'operation': 'curlData',
            'moduleType': 'reseller',
            'element_data': addUserReq,
          });

          addUserReq.action = 'add_reseller';
          addUserReq.resellers = this.company_name;
          addUserReq.email = this.email_id;
          if(this.user_identity == 'enduser' || this.user_identity == 'reseller'){
            addUserReq.dist_id = this.distributor_id;
            addUserReq.reseller_id = this.userId;
          }else{
            addUserReq.dist_id = this.userId;
          }
          addUserReq.password = this.passwords;
          addUserReq.contact_person = this.contact_person;
          addUserReq.phone = this.phone_num;
          addUserReq.reseller_shortname = this.short_name;
          if (this.login_type == 'D') {
            addUserReq.end_user = '0';
          } else if (this.login_type == 'R') {
            addUserReq.end_user = '1';
          }
          this.apiservice.newsendServer(addprofileList).then((response: any) => {
            if (response.result_code == '111') {
              $("#addContactModal").modal("hide");
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Added Successfully',
                showConfirmButton: false,
                timer: 1500
              })
              this.listingusers();
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Added Failed',
                showConfirmButton: false,
                timer: 2000
              })
            }
          }, (error) => {
            console.log(error);
            // this.apiservice.dismiss();
          });
        }else{
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Given Email ID already Existed',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }, (error) => {
        console.log(error);
      });
    }

  }

  listingusers() {
    // {"access_token":"","api_type":"web","operation":"get_reseller","moduleType":"reseller",
    // "element_data":{"action":"get_reseller","dist_id":"349","end_user":"0"}}

    const listUserReq: any = new Object();
    const listprofileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'get_reseller',
      'moduleType': 'reseller',
      'element_data': listUserReq,
    });

    listUserReq.action = 'get_reseller';
    // alert(this.user_identity);
    if(this.user_identity == 'enduser' || this.user_identity == 'reseller'){
      listUserReq.dist_id = this.distributor_id;
      listUserReq.reseller_id = this.userId;
    }else{
      listUserReq.dist_id = this.userId;
    }
    // listUserReq.dist_id = this.userId;
    // if( this.user_identity == 'reseller'){
    //   listUserReq.reseller_id = this.userId;
    //   // addUserReq.reseller_id = this.userId;
    // }else{
    //   listUserReq.dist_id = this.userId;
    // }
    listUserReq.search_text = this.searchValue;
    if (this.login_type == 'D') {
      listUserReq.end_user = '0';
    } else if (this.login_type == 'R') {
      listUserReq.end_user = '1';
    }
    this.apiservice.newsendServer(listprofileList).then((response: any) => {
      if (response.result_code == '111') {
        this.listing_users = response.data;
        this.nodatastate = false;
      } else if (response.result_code == '222') {
        this.nodatastate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }

  edituser(ids) {
    this.part_reseller_id = ids;
    this.editusers = true;
    this.addusers = false;
    if (this.user_type == 'superadmin'){
      var type = 'superadmin';
    }else{
      var type = '';
    }

    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller",
    // "element_data":{"action":"edit_reseller","reseller_id":"2"}}

    const listUserReq: any = new Object();
    const listprofileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': listUserReq,
    });

    listUserReq.action = 'edit_reseller';
    listUserReq.reseller_id = ids;
    listUserReq.type = type;
    this.apiservice.newsendServer(listprofileList).then((response: any) => {
      if (response.result_code == '111') {
        if (this.user_type == 'superadmin'){
          console.log(response.result_data[0]);
          this.company_name = response.result_data[0].companyName;
          this.email_id = response.result_data[0].finance_email;
          this.passwords = response.result_data[0].cust_password;
          this.contact_person = response.result_data[0].contact_person;
          this.phone_num = response.result_data[0].customerPhone;
          this.short_name = response.result_data[0].short_name;
          this.listingusers();
        }else{
          console.log(response.result_data[0]);
          this.company_name = response.result_data[0].reseller;
          this.email_id = response.result_data[0].email;
          this.passwords = response.result_data[0].psw;
          this.contact_person = response.result_data[0].contact_person;
          this.phone_num = response.result_data[0].phone;
          this.short_name = response.result_data[0].reseller_shortname;
          this.listingusers();
        }

      } else {
        $("#addContactModal").modal("hide");
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Editing Failed',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }

  dismissed() {
    $("#company_name").val('');
    $("#email_id").val('');
    $("#contact_person").val('');
    $("#passwords").val('');
    $("#phone_num").val('');
    $("#short_name").val('');

    this.comp_error = false;
    this.email_error = false;
    this.person_error = false;
    this.psd_error = false;
    this.num_error = false;
    this.short_error = false;

  }

  deleteuser(ids) {
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller","element_data":{"action":"delete_reseller","reseller_id":"7"}}
    Swal.fire({
      title: 'Are you sure want to delete',
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
      showCloseButton: true
    }).then((result) => {
      if (result.isConfirmed) {



        const deleteUserReq: any = new Object();
        const deleteprofileList: any = ({
          'access_token': this.accesstoken,
          'api_type': 'web',
          'operation': 'curlData',
          'moduleType': 'reseller',
          'element_data': deleteUserReq,
        })

        deleteUserReq.action = 'delete_reseller';
        deleteUserReq.reseller_id = ids;
        this.apiservice.newsendServer(deleteprofileList).then((response: any) => {
          if (response.result_code == 1) {
            Swal.fire('Saved!', '', 'success');
            this.listingusers();
          }
        }, (error) => {
          console.log(error);
          // this.apiservice.dismiss();
        });

      }
    })
  }


  updateusers() {
    // {"access_token":"","api_type":"web","operation":"curlData",
    // "moduleType":"reseller",
    // "element_data":{"action":"update_reseller","reseller_id":"5",
    // "resellers":"Naveen pte ltd","email":"nb@cal4care.com","password":"password",
    // "contact_person":"ps","phone":"11111111111",
    // "end_user":"0","reseller_shortname":"nb"}}

    const updateUserReq: any = new Object();
    const addprofileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': updateUserReq,
    });


    var newone = $("#company_name").val();
    var newone1 = $("#email_id").val();
    var newone2 = $("#contact_person").val();
    var newone3 = $("#passwords").val();
    var newone4 = $("#phone_num").val();
    var newone5 = $("#short_name").val();
    if (newone.length == 0) {
      this.comp_error = true;
    } else {
      this.comp_error = false;
    }

    if (newone1.length == 0) {
      this.email_error = true;
    } else {
      this.email_error = false;
    }

    if (newone2.length == 0) {
      this.person_error = true;
    } else {
      this.person_error = false;
    }

    if (newone3.length == 0) {
      this.psd_error = true;
    } else {
      this.psd_error = false;
    }

    if (newone4.length == 0) {
      this.num_error = true;
    } else {
      this.num_error = false;
    }


    if (newone5.length == 0) {
      this.short_error = true;
    } else {
      this.short_error = false;
    }



    if (newone.length != 0 && newone1.length != 0 && newone2.length != 0 && newone3.length !== 0 && newone4.length != 0 && newone5.length != 0) {

      updateUserReq.action = 'update_reseller';
      updateUserReq.reseller_id = this.part_reseller_id;
      updateUserReq.resellers = this.company_name;
      updateUserReq.email = this.email_id;
      updateUserReq.password = this.passwords;
      updateUserReq.contact_person = this.contact_person;
      updateUserReq.phone = this.phone_num;
      updateUserReq.reseller_shortname = this.short_name;
      if (this.login_type == 'D') {
        updateUserReq.end_user = '0';
      } else {
        updateUserReq.end_user = '1';
      }
      this.apiservice.newsendServer(addprofileList).then((response: any) => {
        if (response.result_code == '1') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Update Successfully',
            showConfirmButton: false,
            timer: 1500
          });
          $("#addContactModal").modal("hide");
          // $('#addContactModal').modal('toggle');
          this.listingusers();
        }
      }, (error) => {
        console.log(error);
        // this.apiservice.dismiss();
      });
    }
  }


sendWelcomeEmail(ids){
  // {"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJlcnAuY2FsNGNhcmUuY29tIiwiYXVkIjoiZXJwLmNhbDRjYXJlLmNvbSIsImlhdCI6MTY0ODYyNzQ2MSwibmJmIjoxNjQ4NjI3NDYxLCJleHAiOjE2NDg2NDU0NjEsImFjY2Vzc19kYXRhIjp7InRva2VuX2FjY2Vzc05hbWUiOm51bGx9fQ.04DJIZBnZCRJwgqY-LUpcJzUsnemZgxAxmS3_HlReBw","api_type":"web","operation":"send_welcomeemail","moduleType":"profile","element_data":{"action":"send_welcomeemail","customer_id":"30"}}

  const welcomeUserReq: any = new Object();
  const welcomeEmailList: any = ({
    'access_token': this.accesstoken,
    'api_type': 'web',
    'operation': 'send_welcomeemail',
    'moduleType': 'profile',
    'element_data': welcomeUserReq,
  });

  welcomeUserReq.action = 'send_welcomeemail';
  welcomeUserReq.customer_id = ids;

  this.apiservice.newsendServer(welcomeEmailList).then((response: any) => {
alert(response);
    if (response.result_code == '111') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Welcome Email Sent Successfully',
        showConfirmButton: false,
        timer: 1500
      });

    }

  }, (error) => {
    console.log(error);
    // this.apiservice.dismiss();
  });

}



}
