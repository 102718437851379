import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';
declare var iziToast: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  showreset = false;
  username: any;
  constructor(public apiservice:ServicesService) { }

  ngOnInit(): void {
    this.showreset = true;
  }
  

  resetpass() {
 Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: 'Please Wait....',
      imageUrl: "assets/custom-images/pleasewait.gif",
      imageHeight: 80, 
      imageWidth: 80, 
      showConfirmButton: false,
      allowOutsideClick: false
    });
    console.log(this.username);
    const resetReq: any = new Object();
    const Resetlist: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': resetReq,
    });

    resetReq.action = 'reset_pwd';
    resetReq.uname = this.username;
    this.apiservice.newsendServer(Resetlist).then((response: any) => {
      Swal.close();
      console.log(response);
      if (response.status == 'true') {
        Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: "Mail successfully sent",
      icon: 'success',
      showConfirmButton: false,
      timer: 2000
    });
        // this.router.navigate(['/login']);
        // this.showsuccess = true;
        // this.showerror = false;
        this.showreset = false;
      } else if (response.status == 'false') {
           Swal.fire({
      // title: 'Updated',
      //   position: 'top-end',
      title: "Please try again later",
      icon: 'error',
      showConfirmButton: false,
      timer: 2000
    });
        // this.showerror = true;
        // this.showsuccess = false;
        this.showreset = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }

}
