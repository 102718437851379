import { Component, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private apollo: Apollo, private http: HttpClient) { }


  mutations(UPDATE_VAR: any, UPVOTE_POST) {
    return this.apollo.mutate({
      mutation: UPVOTE_POST,
      variables: UPDATE_VAR
    });
  }

  watchQuerys(UPDATE_VAR, QUERY_VAL: any) {
    return this.apollo.watchQuery({
      query: QUERY_VAL,
      variables: UPDATE_VAR
    });
  }


  watchQueryers(QUERY_VAL: any) {
    return this.apollo.watchQuery({
      query: QUERY_VAL
    });
  }

  pagination(list_info) {
    var start, eu, next, back, limit, total_count, offset, last_val, last_final_val, current, pagination, btn_length;
    limit = list_info.page_limit;
    total_count = list_info.total;
    offset = list_info.offset;
    start = 0 + offset;
    eu = start - 0;
    if (total_count < start + 1 && total_count > 1) {

        eu = start - limit;
        start = eu;
    }
    current = eu + limit;
    back = eu - limit;
    next = eu + limit;
    last_val = Math.ceil(total_count / limit);
    last_final_val = (last_val - 1) * limit;
    pagination = { "info": "hide" };
    if (total_count > limit) {
        pagination.info = "show";
        pagination.start = 0;

        if (back >= 0) {
            pagination.back = back;
            pagination.backtab = "show";
        }
        else {
            pagination.backtab = "hide";
        }

        btn_length = 1;
        pagination.data = []
        for (var offset_count = 0; offset_count < total_count; offset_count = offset_count + limit) {

            if ((offset_count <= eu + (2 * limit)) && (offset_count >= eu - (2 * limit))) {

                if (offset_count != eu) {
                    pagination.data.push({ "btn_length": btn_length, "offset_count": offset_count, "load": true });
                }
                else {
                    pagination.data.push({ "btn_length": btn_length, "offset_count": offset_count, "load": false });
                }

            }
            btn_length = btn_length + 1;

        }
        if (current < total_count) {
            pagination.next = next;
            pagination.nexttab = "show";
        }
        else {
            pagination.nexttab = "hide";
        }
        pagination.end = last_final_val;

    }

    return pagination;
}
    
  newsendServer(postData: any[]) {

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json'
    //   })
    // };

    // return this.http.post("https://call4drivers.com/api/", postData,httpOptions);
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');

      this.http.post('https://erp.cal4care.com/nx32/api_nx32/v1.0/', JSON.stringify(postData), { headers: headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  



}
