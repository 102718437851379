import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
declare var iziToast: any;
declare var $: any;
const UPVOTE_POST = gql`
mutation loginMutation($email: String!, $password: String!) {
  login(credentials: {email: $email, password: $password}) {
    accessToken
    id
  }
}
`;

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usernames: any;
  companyname: any;
  passwords: any;
  otpvalue: any;
  logindet: any[];
  showsend = false;
  showlogin = false;
  showotpfield = false;
  IsdisabledLogin = false;
  IsdisabledsendOtp = false;
  types: any;
  user_identity : any;
  constructor(private apollo: Apollo, private apiservice: ServicesService, public router: Router) {

   


   }

  ngOnInit(): void {

    
   
    if(localStorage.getItem('access_token')) {
      this.router.navigate(['/']);
      //return true;


  }
   
   
    this.showsend = true;
    this.types = 'D';
    $(function () {
      var taeb = $(".taeb-switch");
      taeb.find(".taeb").on("click", function () {
        var $this = $(this);
        if ($this.hasClass("active")) return;

        var direction = $this.attr("taeb-direction");

        taeb.removeClass("left right").addClass(direction);
        taeb.find(".taeb.active").removeClass("active");
        $this.addClass("active");


      });
    });

  }
  test(){
    const datas: any = new Object();
    const testdeails: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': datas,
    });
    datas.action = 'getUserdata';
    datas.email = this.usernames;
    datas.psd = this.passwords;
    // {"api_type":"web","operation":"curlData","moduleType":"login","element_data":{"action":"getUserdata","email":"","psd":""}}
    this.apiservice.newsendServer(testdeails).then((response: any) => {
      // alert(response.user_identification);
      this.user_identity = response.user_identification;
      if(this.user_identity == 'distributor'){
        var identify = 'D';
        this.values(identify);
      }
      else if(this.user_identity == 'enduser'){
        var identify = 'R';
        this.values(identify);
      }
      else if(this.user_identity == 'reseller'){
        var identify = 'R';
        this.values(identify);
      }
      this.logincredentials();

    }, (error) => {
      console.log(error);
      alert('error');
      // this.apiservice.dismiss();
    });
  }


  // logindetails() {

  //   this.apiservice.mutations({
  //     email: this.usernames,   //admin@email.com
  //     password: this.passwords  //12345678
  //   }, UPVOTE_POST)
  //     .subscribe(({ data}) => {
  //       console.log('got data', data);
  //       // this.logindet = data.login;
  //       const todosData : any = data;
  //       console.log(todosData.login);
  //       localStorage.setItem('access_token', todosData.login.accessToken);
  //       localStorage.setItem('Id', todosData.login.id);

  //       this.router.navigateByUrl('/');
  //     }, (error) => {
  //       console.log('there was an error sending the query', error);
  //     });

  // }

  values(type) {
    this.types = type;
    // console.log(this.types);
  }

  toast(messages, colors) {
    iziToast.show({
      message: messages,
      position: 'topRight',
      timeout: 3000,
      color: colors,
      theme: 'light'

    });
  }

  logincredentials() {
    this.IsdisabledsendOtp = true;
    if (this.types == 'R') {
      this.logincredentialsreseller();
      // alert("THIS");
    } else {
      this.logincredentialsdist();
      // alert("data");
    }
  }


  logincredentialsreseller() {
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller",
    // "element_data":{"action":"login_reseller","dist_name":"Cal4Care","uname":"ps@cal4care.com","psd":"password"}} 
    
    const otpReq: any = new Object();
    const logincredentiallist: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': otpReq,
    });

    otpReq.action = 'login_reseller';
    // otpReq.dist_name = this.companyname;
    otpReq.uname = this.usernames;
    otpReq.psd = this.passwords;
    this.apiservice.newsendServer(logincredentiallist).then((response: any) => {
      // console.log(response);
      if (response.result_code == '111') {
        // this.showsuccess = true;
        var messages = 'OTP Successfully sent';
        var colors = 'green';
        this.toast(messages, colors);

        this.showsend = false;
        this.showlogin = true;
        this.showotpfield = true;
        // this.showerror = false;
      } else if (response.success_state == 'failed'){
        // this.showerror = true;
        var msg = "Invalid username or password (Please reset your password)";
        this.toast(msg, 'red');
        this.showsend = true;
        this.showlogin = false;
        // this.showsuccess = false;
        this.showotpfield = false;
        this.IsdisabledsendOtp = false;
      }

    }, (error) => {
      console.log(error);
      this.IsdisabledsendOtp = false;
      // this.apiservice.dismiss();
    });

  }


  logincredentialsdist() {
    // this.apiservice.present("Loading");
    this.IsdisabledsendOtp = true;
    // console.log(this.usernames);
    // console.log(this.passwords);
    const otpReq: any = new Object();
    const logincredentiallist: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': otpReq,
    });

    otpReq.action = 'login_validation';
    otpReq.uname = this.usernames;
    otpReq.pwd = this.passwords;
    this.apiservice.newsendServer(logincredentiallist).then((response: any) => {
      // console.log(response);
      if (response.status == 'true') {
        // this.showsuccess = true;
        var messages = 'OTP Successfully sent';
        var colors = 'green';
        this.toast(messages, colors);

        this.showsend = false;
        this.showlogin = true;
        this.showotpfield = true;
        // this.showerror = false;
      } else if (response.status == 'false') {
        // this.showerror = true;
        var msg = "Invalid username or password (Please reset your password)";
        this.toast(msg, 'red');
        this.showsend = true;
        this.showlogin = false;
        // this.showsuccess = false;
        this.showotpfield = false;
        this.IsdisabledsendOtp = false;
      }
    }, (error) => {
      console.log(error);
      this.IsdisabledsendOtp = false;
      // this.apiservice.dismiss();
    });
  }

  loginwithotp() {
    this.IsdisabledLogin = true;
    if (this.types == 'R') {
      this.loginresellerwithotp();
    } else {
      this.logindistwithotp();
    }
  }


  loginresellerwithotp() {
    // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller",
    // "element_data":{"action":"reseller_otp","dist_name":"Cal4Care","uname":"ps@cal4care.com","psd":"password","otp":"929226"}} 

    const loginresellerReq: any = new Object();
    const Logindatalist: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'reseller',
      'element_data': loginresellerReq,
    });

    loginresellerReq.action = 'reseller_otp';
    loginresellerReq.dist_name = this.companyname;
    loginresellerReq.uname = this.usernames;
    loginresellerReq.psd = this.passwords;
    loginresellerReq.otp = this.otpvalue;
    this.apiservice.newsendServer(Logindatalist).then((response: any) => {
      // alert(response.result_data[0].distributor_data);
      if (response.result_code == '111') {
        localStorage.setItem('reseller_company', response.result_data[0].reseller);  
        localStorage.setItem('dist_data', response.result_data[0].distributor_data);
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('customerId', response.result_data[0].id); 
        localStorage.setItem('nx32_customer', response.result_data[0].reseller_shortname);
        localStorage.setItem('nx32_customerName', response.result_data[0].contact_person);
        localStorage.setItem('nx32_customerPhone', response.result_data[0].phone);
        localStorage.setItem('distributor_id', response.result_data[0].distributor_id);
        localStorage.setItem('dist_name', response.result_data[0].dist_name);
        localStorage.setItem('nx32_email', response.result_data[0].email);
        localStorage.setItem('end_user', response.result_data[0].end_user);
        localStorage.setItem('profile_image_url',response.result_data[0].profile_image_url);
        localStorage.setItem('layout', response.result_data[0].layout);
        if (response.result_data[0].theme_color == null || response.result_data[0].theme_color == 'null') {
          localStorage.setItem('theme_color', 'green');
        } else {
          localStorage.setItem('theme_color', response.result_data[0].theme_color);
        }

        localStorage.setItem('login_types', this.types);
        localStorage.setItem('user_identity', this.user_identity);
        localStorage.setItem('login_email_id', this.usernames);
        localStorage.setItem('headers', 'My Devices');
        // return false;
        this.router.navigate(['/']);
    
      } else if (response.result_code == '222') {
          this.showlogin = true;
          // this.showotperror = true;
          var msgs = 'Enter valid OTP';
          this.toast(msgs, 'red');
          this.showotpfield = true;
          // this.showerror = false;
          // this.showsuccess = false;
          this.showsend = false;
          this.IsdisabledLogin = false;
        }

      }, (error) => {
        console.log(error);
        // this.apiservice.dismiss();
      });
  }


  logindistwithotp() {
    this.IsdisabledLogin = true;
    // this.apiservice.present("Loading");
    const loginReq: any = new Object();
    const Logindatalist: any = ({
      'api_type': 'web',
      'operation': 'curlData',
      'moduleType': 'login',
      'element_data': loginReq,
    });

    loginReq.action = 'otp_validation';
    loginReq.uname = this.usernames;
    loginReq.pwd = this.passwords;
    loginReq.otp = this.otpvalue;
    this.apiservice.newsendServer(Logindatalist).then((response: any) => {
      // console.log(response);
      // this.apiservice.dismiss();
      if (response.status == 'true') {

        localStorage.setItem('access_token', response.customer_details.access_token);
        localStorage.setItem('customerId', response.customer_details.customerId);
        localStorage.setItem('nx32_country', response.customer_details.nx32_country);
        localStorage.setItem('nx32_customer', response.customer_details.nx32_customer);
        localStorage.setItem('nx32_customerName', response.customer_details.nx32_customerName);
        localStorage.setItem('nx32_customerPhone', response.customer_details.nx32_customerPhone);
        localStorage.setItem('nx32_customer_status', response.customer_details.nx32_customer_status);
        localStorage.setItem('nx32_def_biller_id', response.customer_details.nx32_def_biller_id);
        localStorage.setItem('nx32_def_currency_id', response.customer_details.nx32_def_currency_id);
        localStorage.setItem('nx32_def_payment_via', response.customer_details.nx32_def_payment_via);
        localStorage.setItem('nx32_email', response.customer_details.nx32_email);
        localStorage.setItem('nx32_login_time', response.customer_details.nx32_login_time);
        localStorage.setItem('nx32_reseller_id', response.customer_details.nx32_reseller_id);
        localStorage.setItem('profile_image_url', response.customer_details.profile_image_url);
        localStorage.setItem('layout', response.customer_details.layout);
        if (response.customer_details.theme_color == null || response.customer_details.theme_color == 'null') {
          localStorage.setItem('theme_color', 'green');
        } else {
          localStorage.setItem('theme_color', response.customer_details.theme_color);
        }

        localStorage.setItem('login_types', this.types);
        localStorage.setItem('login_email_id', this.usernames);
        
        localStorage.setItem('headers', 'My Devices');
        this.router.navigate(['/']);

      } else if (response.status == 'false') {
        this.showlogin = true;
        // this.showotperror = true;
        var msgs = 'Enter valid OTP';
        this.toast(msgs, 'red');
        this.showotpfield = true;
        // this.showerror = false;
        // this.showsuccess = false;
        this.showsend = false;
        this.IsdisabledLogin = false;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });
  }



}
