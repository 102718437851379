import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
declare var $ :any;

@Component({
  selector: 'app-reseller-list',
  templateUrl: './reseller-list.component.html',
  styleUrls: ['./reseller-list.component.css']
})
export class ResellerListComponent implements OnInit {
accesstoken;
userId;
login_type;
endusers;
nodatastate = false;
listing_users: any;

  constructor(private apiservice: ServicesService, public router: Router) { 
    
  }

  ngOnInit(): void {

    this.accesstoken = localStorage.getItem('access_token');
    this.userId = localStorage.getItem('customerId');
    this.login_type = localStorage.getItem('login_types');
    this.endusers = localStorage.getItem('end_user');


  this.resellerlistingusers();
  }


 resellerlistingusers() {
// {"access_token":"","api_type":"web","operation":"get_reseller","moduleType":"reseller","element_data":{"action":"get_reseller","dist_id":"5505","end_user":"all"}}

    const listUserReq: any = new Object();
    const listprofileList: any = ({
      'access_token': this.accesstoken,
      'api_type': 'web',
      'operation': 'get_reseller',
      'moduleType': 'reseller',
      'element_data': listUserReq,
    });

    listUserReq.action = 'get_reseller';
    listUserReq.dist_id = this.userId;
    listUserReq.end_user = 'all';

    this.apiservice.newsendServer(listprofileList).then((response: any) => {
      if (response.result_code == '111') {
       
        this.listing_users = response.data;
        this.nodatastate = false;
      } else if (response.result_code == '222') {
        this.nodatastate = true;
      }
    }, (error) => {
      console.log(error);
      // this.apiservice.dismiss();
    });

  }

}
