import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

// const token = localStorage.getItem('access_token');

// const uri = 'http://139.59.94.69:8080/v1/graphql'; // <-- add the URL of the GraphQL server here
const uri = 'https://nxhandler.call4tel.com/v1/graphql'; // <-- add the URL of the GraphQL server here
// export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
//   return {
//     link: httpLink.create({ uri }),
//     cache: new InMemoryCache(),
//     headers: {
//       Authorization: `Bearer ${token || null}`,
//     },
//   };
// }



export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  // Create an http link:
  const http = httpLink.create({
    uri: uri,
  });

  // Create a WebSocket link:
  const ws = new WebSocketLink({
    // uri: `ws://139.59.94.69:8080/v1/graphql`,
    uri: `wss://nxhandler.call4tel.com/v1/graphql`,
    options: {
      reconnect: true,
      // connectionParams: {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('access_token') || null}`
      //   }
      // }
    },
  });

  // const auth = setContext((operation, context) => {
  //   const token = localStorage.getItem('access_token');

  //   if (token === null) {
  //     return {};
  //   } else {
  //     return {
  //       headers: {
  //         Authorization: `Bearer ${token || null}`
  //       }
  //     };
  //   }
  // });
  const auth = setContext((operation, context) => {

      return {
        headers: {
          "x-hasura-admin-secret":"backendsecretkey"
        }
      };
  
  });

  // const token = localStorage.getItem('access_token');
  // const link2 = setContext((request, previousContext) => ({

  //   headers: {
  //     'x-hasura-admin-secret': token
  //   }
  // })).concat(http);

  const httplink = ApolloLink.from([basic, auth, http]);
  // const wslink = ApolloLink.from([basic, auth, ws]);


  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    ws,
    httplink
  );

  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}





@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
