import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
declare var $:any;
import { ServicesService } from '../services.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userId: any;

  login_types: any;
  city: any;
  contact_us_url: any;
  country: any;
  cus_permission: any;
  customerAddress1: any;
  customerAddress2: any;
  customerId: any;
  customerName: any;
  customerPhone: any;
  email: any;
  state: any;
  zipCode: any;
  logo: any;
  userperms: any;
  pro_img: any;
  user_identity :any;
  accesstoken: any;
  profile_image_url: any;
  showfields = false;
  distributor = false;
  enduser = false;
  reseller = false;
  repasserror = false;
  resetpass = false;
  constructor(private apiservice: ServicesService, public router: Router) { 
    this.accesstoken = localStorage.getItem('access_token');
    this.login_types = localStorage.getItem('login_types');
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('customerId');
    this.profile_image_url = localStorage.getItem('profile_image_url');
    this.user_identity = localStorage.getItem('user_identity');
    this.logoshow();
    this.loginDisplayDevices();
  }
  logoshow(){
    if(this.user_identity == 'distributor'){
      this.distributor = true;
      this.reseller = false;
      this.enduser = false;
    }
    else if(this.user_identity == 'enduser'){
      this.distributor = false;
      this.reseller = false;
      this.enduser = true;
      this.resetpass = true;
    }
    else if(this.user_identity == 'reseller'){
      this.distributor = false;
      this.reseller = false;
      this.enduser = true;
      this.resetpass = true;
    }

  }
  loginDisplayDevices() {
    if (this.login_types == 'D') {
      this.listProfileDetails();

    } else if (this.login_types == 'R') {
      this.ResellerlistProfileDetails();
    }
  }

testing(){
  // [routerLink]="['/user-edit']"
  // this.router.navigateByUrl("/user-edit");
  this.router.navigate(['/user-edit']);
}

listProfileDetails() {

  const ProfiledetailsReq: any = new Object();
  const profileList: any = ({
    'access_token': this.accesstoken,
    'api_type': 'web',
    'operation': 'curlData',
    'moduleType': 'login',
    'element_data': ProfiledetailsReq,
  });

  ProfiledetailsReq.action = 'profile_details';
  ProfiledetailsReq.nx32_customer_id = this.userId;
  // ProfiledetailsReq.nx32_customer_id = '349';
  this.apiservice.newsendServer(profileList).then((response: any) => {
    if (response.status == 'true') {
      // customer_details: {
      //   city: "Coimbatore"
      //   contact_us_url: null
      //   country: "India"
      //   cus_permission: ["101", "102", "103", "105", "104", "109", "134", "110", "118", "156", "157", "151", "152", "153",]
      //   customerAddress1: "Coimbatore"
      //   customerAddress2: "CBE"
      //   customerId: "5228"
      //   customerName: "navin"
      //   customerPhone: "8220733686"
      //   email: "nb@cal4care.com,test@cal4care.com"
      //   logo: "https://erp.cal4care.com/nx32/images/logo-white.svg"
      //   state: "Tamil Nadu"
      //   zipCode: ""
      // }
      
      this.city = response.customer_details.city;
      this.contact_us_url = response.customer_details.contact_us_url;
      this.country = response.customer_details.country;
      this.cus_permission = localStorage.setItem('userperms', response.customer_details.cus_permission);
      this.customerAddress1 = response.customer_details.customerAddress1;
      this.customerAddress2 = response.customer_details.customerAddress2;
      this.customerId = response.customer_details.customerId;
      this.customerName = response.customer_details.customerName;
      this.customerPhone = response.customer_details.customerPhone;
      this.logo = response.customer_details.logo;
      // this.email = response.customer_details.email;
      this.email = localStorage.getItem('login_email_id');
      this.state = response.customer_details.state;
      this.zipCode = response.customer_details.zipCode;
      this.profile_image_url = response.customer_details.pro_img;

      this.userperms = localStorage.getItem('userperms').split(',');

      for (var i = 0; i < this.userperms.length; i++) {
        if (this.userperms[i] == '155')
          this.showfields = true;
      }

    } else if (response.status == 'false') {

    }
  }, (error) => {
    console.log(error);
    // this.apiservice.dismiss();
  });

}


ResellerlistProfileDetails() {


  // {"access_token":"","api_type":"web","operation":"curlData","moduleType":"reseller",
  // "element_data":{"action":"edit_reseller","reseller_id":"17"}}
  const ProfiledetailsReq: any = new Object();
  const profileList: any = ({
    'access_token': this.accesstoken,
    'api_type': 'web',
    'operation': 'curlData',
    'moduleType': 'reseller',
    'element_data': ProfiledetailsReq,
  });

  ProfiledetailsReq.action = 'edit_reseller';
  ProfiledetailsReq.reseller_id = this.userId;
  // ProfiledetailsReq.nx32_customer_id = '349';
  this.apiservice.newsendServer(profileList).then((response: any) => {
    if (response.result_code == '111') {
      // customer_details: {
      //   city: "Coimbatore"
      //   contact_us_url: null
      //   country: "India"
      //   cus_permission: ["101", "102", "103", "105", "104", "109", "134", "110", "118", "156", "157", "151", "152", "153",]
      //   customerAddress1: "Coimbatore"
      //   customerAddress2: "CBE"
      //   customerId: "5228"
      //   customerName: "navin"
      //   customerPhone: "8220733686"
      //   email: "nb@cal4care.com,test@cal4care.com"
      //   logo: "https://erp.cal4care.com/nx32/images/logo-white.svg"
      //   state: "Tamil Nadu"
      //   zipCode: ""
      // }

      // result_data: [{id: "17", distributor_id: "5228", dist_name: "nb", reseller: "Navin Babu PTe LTD",…}]
      // 0: {id: "17", distributor_id: "5228", dist_name: "nb", reseller: "Navin Babu PTe LTD",…}
      // contact_person: "babu"
      // created_on: "2021-05-17 18:42:15"
      // dist_name: "nb"
      // distributor_id: "5228"
      // email: "nb@cal4care.com"
      // end_user: "0"
      // id: "17"
      // layout: "light"
      // password: "YmFidQ=="
      // phone: "1234567890"
      // psw: "babu"
      // reseller: "Navin Babu PTe LTD"
      // reseller_shortname: "babu"
      // theme_color: "green"
      // result_msg: "Reseller data Retrived successfully"
      
      console.log(response.result_data[0].distributor_id);
      
      this.city = response.result_data[0].city;
      this.contact_us_url = response.result_data[0].contact_us_url;
      this.country = response.result_data[0].country;
      this.cus_permission = localStorage.setItem('userperms', response.result_data[0].cus_permission);
      this.customerAddress1 = response.result_data[0].customerAddress1;
      this.customerAddress2 = response.result_data[0].customerAddress2;
      this.customerId = response.result_data[0].id;
      this.customerName = response.result_data[0].contact_person;
      this.customerPhone = response.result_data[0].phone;
      this.logo = response.result_data[0].logo;
      // this.email = response.result_data[0].email;
      this.email = localStorage.getItem('login_email_id');
      this.state = response.result_data[0].state;
      this.zipCode = response.result_data[0].zipCode;
      this.profile_image_url = response.result_data[0].pro_img;

      this.userperms = localStorage.getItem('userperms').split(',');

      for (var i = 0; i < this.userperms.length; i++) {
        if (this.userperms[i] == '155')
          this.showfields = true;
      }

    } else if (response.result_code == '222') {

    }
  }, (error) => {
    console.log(error);
    // this.apiservice.dismiss();
  });

}
//Password Reset//
updatepassword(){
  if($('#new_password').val() == $('#re_password').val()){
  const passreset: any = new Object();
  const profileList: any = ({
    'access_token': this.accesstoken,
    'api_type': 'web',
    'operation': 'pass',
    'moduleType': 'pass',
    'element_data': passreset,
  });
  passreset.action = 'new_pass';
  passreset.reseller_id = this.userId;
  passreset.password = $('#old_password').val();
  passreset.newpassword = $('#new_password').val();
  // ProfiledetailsReq.nx32_customer_id = '349';
  this.apiservice.newsendServer(profileList).then((response: any) => {
    if (response.result_code == '111') {
      Swal.fire("Password Successfully Changed");
    }else{
      Swal.fire("Error");
    }
    });

}else{
  Swal.fire("Re-type correct passsword");
}

}
}