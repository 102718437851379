import { Component } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Router } from "@angular/router";
import '../assets/js/libs/app.js';
import { exit } from 'process';
declare var $: any;
declare var init: any;
declare var App: any;
declare var test: any;
declare var test: any;
declare function blackThemeFunc(): any;
declare function whiteThemeFunc(): any;
declare function whiteThemeClr(): any;
declare function blackThemeClr(): any;
declare function blueThemeClr(): any;
declare function greenThemeClr(): any;
declare function redThemeClr(): any;

// const UPVOTE_POST = gql`
// mutation loginMutation($email: String!, $password: String!) {
//   login(credentials: {email: $email, password: $password}) {
//     accessToken
//     id
//   }
// }
// `;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ng-graphql';
  self: any;
  headingvalues: any;
  layouts: any;
  themes_color: any;
  userId: any;
  distname :any;
  custname : any;
  disti_name : any;
  resellername : any;
  public file_path: string = "";
  templateAuthView = false;
  profile_image_url: any;
  endusers: any;
  login_types: any;
  showdist =false;
  showreseller =false;
  showend =false;
  hidemenu = true;


  constructor(private apollo: Apollo, public router: Router) {
    console.log('app components');
    // this.testinglogin();
    // App.init();
    this.profile_image_url = localStorage.getItem('profile_image_url');
    this.layouts = localStorage.getItem('layout');
    this.userId = localStorage.getItem('customerId');
    this.themes_color = localStorage.getItem('theme_color');
    this.endusers = localStorage.getItem('end_user');
    this.login_types = localStorage.getItem('login_types');
    this.custname = localStorage.getItem('nx32_customerName');
    this.distname = localStorage.getItem('dist_name');
    this.disti_name = localStorage.getItem('distri_name');
    this.resellername = localStorage.getItem('resell_name');

    if(this.login_types == 'D'){
      this.showdist = true;
      this.showreseller =false;
      this.showend=false;
      this.hidemenu = true;
    }else if(this.login_types == 'R'){
      if(this.endusers == 0){
        this.showdist = false;
        this.showreseller =true;
        this.showend=false;
        this.hidemenu = true;
      }else if(this.endusers == 1){
        this.showdist = false;
        this.showreseller =false;
        this.showend=true;
        this.hidemenu = false;
      }
    }
    // $('body').removeClass();
    // $('body').addClass(this.layouts);
    if (this.layouts == 'light') {
      whiteThemeFunc();
    } else if (this.layouts == 'dark') {
      blackThemeFunc();
    }

this.sidebarcolor();

  }

  ngOnInit(): void {
    this.displayheading();
    // this.idleLogout();

  }
  idleLogout() {
    console.log("insidel");
    var t :any;
    var self = this;
    // var a = this.resetTimer();
    window.onload = resetTimer;
    // this.resetTimer = this.ResetTimer();
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well      
    window.ontouchmove = resetTimer;  // required by some devices 
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeydown = resetTimer;   
    window.addEventListener('scroll', resetTimer, true); // improved; see comments
    // if(this.resetTimer == null){
    //   alert("insetlog");
    //   this.resetTimer();
    // }
    // else{
    //   alert("hello");
    // }
    function yourfunction(){
      // alert("iside");
      self.yourFunction();
    }
    function resetTimer() {
        clearTimeout(t);
        t = setTimeout(yourfunction,18000);  // time is in milliseconds
    }
   
    // console.log(yourfunction)
    // if(yourfunction){
    //   alert("hi");
    // }
}
yourFunction(){
  // alert("hello");
  localStorage.clear();
  this.logout();
}







  displayheading() {
    this.headingvalues = localStorage.getItem('headers');
  }
  // testinglogin() {
  //   console.log("hello testing");

  //   this.apollo.mutate({
  //     mutation: UPVOTE_POST,
  //     variables: {
  //       email: 'admin@email.com',
  //       password:'12345678'
  //     }
  //   }).subscribe(({ data }) => {
  //     console.log('got data', data);
  //   }, (error) => {
  //     console.log('there was an error sending the query', error);
  //   });
  // }


  // routing(){
  //   this.router.navigateByUrl("/testing");
  // }

  onActivate(event) {

    $('.list-unstyled li a').click(function () {
      console.log("hello navin");
      // self.onActivate('test');
      $("ul li a").removeClass("actives");
      $(this).parent().find('a').addClass("actives");
    });

    this.file_path = this.router.url;
    console.log(this.router.url);
    if (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== '' && localStorage.getItem('access_token') !== undefined) {
      console.log('acess_token');
      this.templateAuthView = false;
    } else if (this.router.url == '/forgot-password') {
      console.log('forgot_password');
      this.templateAuthView = true;
      this.router.navigate(['/forgot-password']);
    } else if (this.router.url == '/login') {
      console.log('logins');
      this.templateAuthView = true;
      this.router.navigate(['/login']);
    } else if (this.router.url == '/reset-password') {
      console.log('reset_password');
      this.templateAuthView = true;
      this.router.navigate(['/reset-password']);
    } else {
      console.log('else_login');
      this.templateAuthView = true;
      this.router.navigate(['/login']);
    }
  }


  sendheading(heads) {
    // id="headers"
    // alert('hello values');
    // var newone = $('#headers').text();
    localStorage.setItem('headers', heads);
    // alert(newone);
    this.displayheading();

  }

  clickLicense(testing){
    // [routerLink]="['/license-list']" 
    console.log(testing);
    // this.router.navigate(['/'+testing])
    this.router.navigateByUrl('/'+testing);
  // .then(() => {
  //   window.location.reload();
  // });
      
  // $('.list-unstyled li a').click(function () {
  //   console.log("hello navin");
  //   // self.onActivate('test');
  //   $("ul li a").removeClass("actives");
  //   $(this).parent().find('a').addClass("actives");
  // });
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  goToDownloads (){
    window.open('https://helpdesk.guru/kb/faq.php?cid=21', '_blank');
  }
  goToShop(){
    window.open('https://shop.call4tel.com/', '_blank');
  }
  contactDistributor(){
    // window.open('https://www.call4tel.com/our-partners/', '_blank');
    window.open('https://www.call4tel.com/contact-us/', '_blank');
  }
  visitBlog(){
    window.open('https://www.call4tel.com/blog/', '_blank');
  }

  // toggleMenu() {
    // let menuSidebar = document.getElementById("sidebarWrapper");
    // menuSidebar.classList.toggle("toggleSideMenuSize");

    // let sideBarMainClass = document.getElementById("sidebarStatus");
    // sideBarMainClass.classList.toggle("sideBarMainClass");
  // }


  changeOpacity(){
    document.getElementById("#sidebarWrapper").style.width = "470px";
    document.getElementById("#customTheme").style.opacity = ".3";
  }



  // $(function() {
  //   $('#sidebarWrapper').mouseenter(function() {
  //     $('body').addClass("inverse");
  //   }).mouseleave(function() {
  //     $('body').removeClass("inverse");
  //   });
  // });



  sidebarcolor(){
      if(this.themes_color == 'white') {
      // whiteThemeClr();
      $("#sidebarTheme").removeClass();
      $("#sidebarTheme").addClass('white-sidebar-theme');

    } else if(this.themes_color == 'black') {
      // blackThemeClr();
      $("#sidebarTheme").removeClass();
      $("#sidebarTheme").addClass('black-sidebar-theme');
    } else if(this.themes_color == 'blue') {
      // blueThemeClr();
      $("#sidebarTheme").removeClass();
      $("#sidebarTheme").addClass('blue-sidebar-theme');
    } else if(this.themes_color == 'green') {
      // greenThemeClr();
      $("#sidebarTheme").removeClass();
      $("#sidebarTheme").addClass('green-sidebar-theme');
    } else if(this.themes_color == 'red') {
      // redThemeClr();
      $("#sidebarTheme").removeClass();
      $("#sidebarTheme").addClass('red-sidebar-theme');
    }
  }



}



