import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ServicesService } from '../services.service';




@Component({
  selector: 'app-new-component',
  templateUrl: './new-component.component.html',
  styleUrls: ['./new-component.component.css']
})


export class NewComponentComponent implements OnInit {
  websocket:any;

  constructor(private apollo: Apollo, private apiservice: ServicesService) {
    console.log('newcomponents');
   }

  ngOnInit(): void {
    this.initSocket();
  }

  initSocket(){
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4021"); 
  
  this.websocket.onopen = function(event) { 
    // $('#sendonload').click();
    console.log('agent socket connected');
  }

  this.websocket.onmessage = function(event) {
  // console.log(event.data);
    var result_message = JSON.parse(event.data);
    // this.hardware_id = localStorage.getItem('hardware_id');
    console.log(result_message);
    // if(result_message[0].cust_id == this.hardware_id){
    //  console.log('matched');
    //  console.log(result_message);
 
    //   // this.callonce = setTimeout(() => {
    //   //   // this.closeLoading();
    //   //     Swal.close();
    //   // }, 15000);
      
    // } else {
    //   console.log('not matched');
    //   // Swal.close();

    //   return false;
    // }

    //  if(result_message[0].data[0].status=="false"){   
    //   // $('#datagetsfailed').click();
    //   // Swal.close();

    // } else if(result_message[0].user_list=="user_listFrom3cx"){

    //   // $('#AgentsFrom3cx').val(event.data);
    //   // $('#AgentsFrom3cx').click();
    //   // this.dynamicUsers = result_message[0].data;
    //   //console.log(this.dynamicUsers);
    //   // $('#addAllAgentsFrom3cx').modal('show');
    // }
    // else if(result_message[0].data[0].sipdata=="getagentdet"){
    
    //   // $('#sip_username_add').val(result_message[0].data[0].sip_username);
    //   // $('#sip_password_add').val(result_message[0].data[0].sip_password);
    //   // $('#sip_username').val(result_message[0].data[0].sip_username);
    //   // $('#u_sip_password').val(result_message[0].data[0].sip_password);
    //   // Swal.close();

 
    // }
  }
  this.websocket.onerror = function(event){
    // Swal.close();

    console.log('error');
  }
  this.websocket.onclose = function(event){
    // Swal.close();

    console.log('close');
  }
  }






}

